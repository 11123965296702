import { render, staticRenderFns } from "./sucursales.vue?vue&type=template&id=133c7a26&scoped=true"
import script from "./sucursales.vue?vue&type=script&lang=js"
export * from "./sucursales.vue?vue&type=script&lang=js"
import style0 from "./sucursales.vue?vue&type=style&index=0&id=133c7a26&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "133c7a26",
  null
  
)

export default component.exports